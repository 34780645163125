import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
  width: 500px;
  height: 500px;
  margin: auto;
  margin-top: -50px;
  z-index: 6;
`

export const StarContainer = styled.img`
  width: 400px;
  height: 400px;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  margin: auto;
	z-index: 5;
`

export const StarLoopContainer = styled.img`
	display: none;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  margin: auto;
	z-index: 5;
`

export const RayContainer = styled.img`
	width: 600px;
	height: 600px;
	position: absolute;
	top: -30px;
	left: -37px;
	right: 0;
	margin: auto;
	z-index: -1;
	animation: rayrotate 5s linear infinite;
	z-index: 4;

  @keyframes rayrotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Overlay = styled.div`
	position: fixed;
	top: -500vh;
	left: -250vw;
	width: 1000vw;
	height: 1000vh;
	z-index: 3;
	background: rgba(0, 0, 0, 0.3);
`

export const ExplosionContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  canvas {
    margin: auto !important;
  }
`

export const StarMovementContainer = styled.div`
  position: absolute;
  top: -56px;
  right: -22px;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

export const MovingStar = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  animation: move 0.4s linear forwards;
  animation-delay: ${prop => prop.delay}s;
  opacity: 0;

  @keyframes move {
    0% {
      opacity: 1;
      top: 0;
      right: 0;
    }

    100% {
      opacity: 1;
      top: 200px;
      right: 170px;
    }
  }
`

export const MovingStarDown = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  animation: move2 0.4s linear forwards;
  animation-delay: ${prop => prop.delay}s;
  opacity: 0;

  @keyframes move2 {
    0% {
      opacity: 1;
      top: 0;
      right: 0;
    }

    100% {
      opacity: 1;
      top: 320px;
      right: 150px;
    }
  }
`